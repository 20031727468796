
import Header from '@/components/core/Header';
import BackHeader from '@/components/core/BackHeader';
import Footer from '@/components/core/Footer';
import Interlinks from '@/components/core/Interlinks';

export default {
  components: {
    Header,
    BackHeader,
    Footer,
    Interlinks


  }, props: {
    traslucentWhite: {
      type: Boolean,
      default: false
    }
  },
  head() {
    return {
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.siteUrl + this.$route.path
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://www.goa.app' + this.$route.path
        }
      ]
    }
  },
  data() {
    return {
      interLinksArr: null
    }
  },
  watch: {
    //to watch for route change to refresh the interlinks obj
    $route(to, from) {
      if (to && from && (to.name != from.name)) {
        this.interLinksArr = null
      }
    },
  },

  methods: {
    // map the links received from child component
    receivedInterLinks(value) {
      this.interLinksArr = value;
    }
  }
}

